<template>
  <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
    <template v-slot:title v-if="hasTitleSlot">
      <h3 class="card-title">
        <slot name="title"></slot>
      </h3>
    </template>
    <template v-slot:toolbar>
      <div class="example-tools justify-content-center">
        <slot name="toolbar-action"></slot>
      </div>
    </template>
    <template v-slot:body>
      <slot name="preview"></slot>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card.vue";

export default {
  name: "KTCodePreview",
  props: {
    title: String
  },
  data() {
    return {};
  },
  components: {
    KTCard
  },
  mounted() {},
  methods: {},
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  }
};
</script>
