<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Cesja</b> w tym miejscu mozesz cedować swoje punkty na innego uczestnika AGROPULI.
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          title="Lista zgłoszonych cesji"
          v-if="state === 'loaded'"
        >
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                :items="transfers.items"
                :fields="transfers.fields"
                :filter="filter"
                :tbody-tr-class="rowClass"
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="editTransfer(row.item)"
                  >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>
    <!-- Begin Modal with transfer details -->
    <template>
      <b-modal
        id="editTransfer"
        size="xl"
        scrollable
        ref="transferDetail"
        :title="'Status: ' + transfer.status"
      >
        <b-container>
          <div class="row">
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Cesja na:
                    </div>
                    <div class="col-md-6">
                      <b-badge variant="success">{{ transfer.cardNo }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Akronim:
                    </div>
                    <div class="col-md-6">
                      {{ transfer.erpUser.acronym }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      E-mail:
                    </div>
                    <div class="col-md-6">
                      {{ transfer.erpUser.email }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Cedowane punkty:
                    </div>
                    <div class="col-md-6">
                      <b-badge variant="warning">{{ transfer.points }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Imię nazwisko:
                    </div>
                    <div class="col-md-6">
                      {{ transfer.erpUser.person }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Telefon:
                    </div>
                    <div class="col-md-6">
                      {{ transfer.erpUser.phone }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <hr />
          <b-form novalidate :validated="formValidated">
            <b-form-group
              id="fieldset-1"
              label="Odpowiedź Administratora"
              label-for="description"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    v-model="transfer.response"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    Proszę wpisać odpowiedź.
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
        </b-container>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button
            variant="success"
            size="sm"
            class="float-right"
            @click="saveTransfer('ZAAKCEPTOWANA')"
          >
            Akceptuj
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="saveTransfer('ODRZUCONA')"
          >
            Odrzuć
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="hide()"
          >
            Zamknij
          </b-button>
        </template>
      </b-modal>
    </template>
    <!-- End Modal with transfer details -->
  </div>
</template>
<style>
.action-column {
  width: 50px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview";

export default {
  name: "TransferAdmin",
  data() {
    return {
      state: "loading",
      transfers: {
        fields: [
          {
            key: "date",
            label: "Data",
            sortable: true
          },
          {
            key: "erpUser.acronym",
            label: "Akronim",
            sortable: true
          },
          {
            key: "points",
            label: "Punkty",
            sortable: true
          },
          {
            key: "cardNo",
            label: "Na kartę",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column"
          }
        ],
        items: []
      },
      transfer: {
        erpUser: {}
      },
      filter: null,
      formValidated: false
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "ODRZUCONA") return "table-danger";
      if (item.status === "ZAAKCEPTOWANA") return "table-success";
    },
    getTransfers() {
      ApiService.get("/all-transfers").then(response => {
        this.transfers.items = response.data;
        this.state = "loaded";
      });
    },
    editTransfer(row) {
      this.formValidated = false;
      this.transfer = row;
      this.$refs.transferDetail.show("editTransfer");
    },
    saveTransfer(status) {
      if (this.transfer.response && this.transfer.response.trim().length > 0) {
        const oldStatus = this.transfer.status;
        this.transfer.status = status;

        ApiService.post("/transfer-save", this.transfer)
          .then(() => {
            this.$refs.transferDetail.hide("editTransfer");
          })
          .catch(() => {
            this.formValidated = true;
            this.transfer.status = oldStatus;
          });
        } else {
        this.formValidated = true;
      }
    },
    status(transfer) {
      switch (transfer.status) {
        case "ZŁOŻONA":
          return "Złożona";
        case "ODRZUCONA":
          return "Odrzucona";
        case "ZAAKCEPTOWANA":
          return "Zaakceptowana";
        default:
          return "Nieznany";
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Cesje" },
      { title: "" }
    ]);
    this.getTransfers();
  }
};
</script>
